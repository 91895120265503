<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateGrantsConstraintsPlans()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'grantsConstraintsPlansCode'"
            :value="grantsConstraintsPlan.grantsConstraintsPlansCode"
            v-on:changeValue="
              grantsConstraintsPlan.grantsConstraintsPlansCode = $event
            "
            :title="$t('GrantsConstraintsPlans.code')"
            :imgName="'code.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'grantsConstraintsPlansNameAr'"
            :value="grantsConstraintsPlan.grantsConstraintsPlansNameAr"
            v-on:changeValue="
              grantsConstraintsPlan.grantsConstraintsPlansNameAr = $event
            "
            :title="$t('GrantsConstraintsPlans.nameAr')"
            :imgName="'GrantsConstraintsPlans.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'grantsConstraintsPlansNameEn'"
            :value="grantsConstraintsPlan.grantsConstraintsPlansNameEn"
            v-on:changeValue="
              grantsConstraintsPlan.grantsConstraintsPlansNameEn = $event
            "
            :title="$t('GrantsConstraintsPlans.nameEn')"
            :imgName="'GrantsConstraintsPlans.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'grantsConstraintsPlansNameUnd'"
            :value="grantsConstraintsPlan.grantsConstraintsPlansNameUnd"
            v-on:changeValue="
              grantsConstraintsPlan.grantsConstraintsPlansNameUnd = $event
            "
            :title="$t('GrantsConstraintsPlans.nameUnd')"
            :imgName="'GrantsConstraintsPlans.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'grantsConstraintsPlansNote'"
            :value="grantsConstraintsPlan.grantsConstraintsPlansNote"
            v-on:changeValue="
              grantsConstraintsPlan.grantsConstraintsPlansNote = $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div v-if="submitName == $t('add')">
        <div
          class="my-card"
          v-for="(
            slide, index
          ) in grantsConstraintsPlan.grantsConstraintsPlansSlidesDetailsData"
          :key="index"
        >
          <span class="my-card-title">{{ index + 1 }}</span>

          <span class="remove-slice-container" v-if="index != 0">
            <button
              @click="removeSlice(index)"
              type="button"
              class="btn btn-remove-slice"
            >
              ×
            </button>
          </span>
          <div class="row">
            <CustomSelectBox
              :className="'col-md-6'"
              :id="`slide[${index}][grantTypeToken]`"
              :value="slide.grantTypeToken"
              :options="grantTypeTokenOptions"
              v-on:changeValue="slide.grantTypeToken = $event"
              :title="$t('Grants.grantTypeSelect')"
              :imgName="'type.svg'"
            />
            <CustomCheckbox
              :value="slide.sequentialCalculationOfHourFactorAndValue"
              v-on:changeValue="
                slide.sequentialCalculationOfHourFactorAndValue = $event
              "
              :title="
                $t(
                  'GrantsConstraintsPlans.sequentialCalculationOfHourFactorAndValue'
                )
              "
              :className="'col-md-6'"
            />

            <!-- #region MaximumNumber -->
            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumNumberInDay]`"
              :value="slide.grantsConstraintsMaximumNumberInDay"
              v-on:changeValue="
                slide.grantsConstraintsMaximumNumberInDay = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumNumberInDay')"
              :imgName="'number.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="slide.grantsConstraintsMaximumNumberInDayActivationStatus"
              v-on:changeValue="
                slide.grantsConstraintsMaximumNumberInDayActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />

            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumNumberInMonth]`"
              :value="slide.grantsConstraintsMaximumNumberInMonth"
              v-on:changeValue="
                slide.grantsConstraintsMaximumNumberInMonth = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumNumberInMonth')"
              :imgName="'number.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="
                slide.grantsConstraintsMaximumNumberInMonthActivationStatus
              "
              v-on:changeValue="
                slide.grantsConstraintsMaximumNumberInMonthActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />

            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumNumberInYear]`"
              :value="slide.grantsConstraintsMaximumNumberInYear"
              v-on:changeValue="
                slide.grantsConstraintsMaximumNumberInYear = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumNumberInYear')"
              :imgName="'number.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="
                slide.grantsConstraintsMaximumNumberInYearActivationStatus
              "
              v-on:changeValue="
                slide.grantsConstraintsMaximumNumberInYearActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />
            <!-- #endregion MaximumNumber -->

            <!-- #region MaximumCost -->
            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumCostInDay]`"
              :value="slide.grantsConstraintsMaximumCostInDay"
              v-on:changeValue="
                slide.grantsConstraintsMaximumCostInDay = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumCostInDay')"
              :imgName="'money.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="slide.grantsConstraintsMaximumCostInDayActivationStatus"
              v-on:changeValue="
                slide.grantsConstraintsMaximumCostInDayActivationStatus = $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />

            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumCostInMonth]`"
              :value="slide.grantsConstraintsMaximumCostInMonth"
              v-on:changeValue="
                slide.grantsConstraintsMaximumCostInMonth = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumCostInMonth')"
              :imgName="'money.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="slide.grantsConstraintsMaximumCostInMonthActivationStatus"
              v-on:changeValue="
                slide.grantsConstraintsMaximumCostInMonthActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />

            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumCostInYear]`"
              :value="slide.grantsConstraintsMaximumCostInYear"
              v-on:changeValue="
                slide.grantsConstraintsMaximumCostInYear = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumCostInYear')"
              :imgName="'money.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="slide.grantsConstraintsMaximumCostInYearActivationStatus"
              v-on:changeValue="
                slide.grantsConstraintsMaximumCostInYearActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />
            <!-- #endregion MaximumCost -->

            <!-- #region MaximumHourFactor -->
            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumHourFactorInDay]`"
              :value="slide.grantsConstraintsMaximumHourFactorInDay"
              v-on:changeValue="
                slide.grantsConstraintsMaximumHourFactorInDay = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumHourFactorInDay')"
              :imgName="'number.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="
                slide.grantsConstraintsMaximumHourFactorInDayActivationStatus
              "
              v-on:changeValue="
                slide.grantsConstraintsMaximumHourFactorInDayActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />

            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumHourFactorInMonth]`"
              :value="slide.grantsConstraintsMaximumHourFactorInMonth"
              v-on:changeValue="
                slide.grantsConstraintsMaximumHourFactorInMonth = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumHourFactorInMonth')"
              :imgName="'number.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="
                slide.grantsConstraintsMaximumHourFactorInMonthActivationStatus
              "
              v-on:changeValue="
                slide.grantsConstraintsMaximumHourFactorInMonthActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />

            <CustomInputInt
              :className="'col-md-6'"
              :id="`slide[${index}][grantsConstraintsMaximumHourFactorInYear]`"
              :value="slide.grantsConstraintsMaximumHourFactorInYear"
              v-on:changeValue="
                slide.grantsConstraintsMaximumHourFactorInYear = $event
              "
              :title="$t('GrantsConstraintsPlans.MaximumHourFactorInYear')"
              :imgName="'number.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6'"
              :value="
                slide.grantsConstraintsMaximumHourFactorInYearActivationStatus
              "
              v-on:changeValue="
                slide.grantsConstraintsMaximumHourFactorInYearActivationStatus =
                  $event
              "
              :title="$t('GrantsConstraintsPlans.status')"
            />
            <!-- #endregion MaximumHourFactor -->

            <TextArea
              :className="'col-md-12'"
              :id="`slide[${index}][grantsConstraintsPlansSlidesDetailsNote]`"
              :value="slide.grantsConstraintsPlansSlidesDetailsNote"
              v-on:changeValue="
                slide.grantsConstraintsPlansSlidesDetailsNote = $event
              "
              :title="$t('notes')"
              :imgName="'notes.svg'"
            />
          </div>
        </div>

        <div class="add-slice-container">
          <button
            @click="addSlice"
            type="button"
            class="btn btn-add-slice"
            :title="$t('addSlice')"
          >
            +
          </button>
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="{ name: 'GrantsConstraintsPlans' }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TextArea from "@/components/general/TextArea.vue";
import { setDataMultiLang } from "@/utils/functions";
import createToastMixin from "@/utils/create-toast-mixin";
import GrantsConstraintsPlansSlidDetails from "@/models/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetails";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    CustomInput,
    CustomInputInt,
    CustomCheckbox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      grantTypeTokenOptions: [],
    };
  },
  props: ["grantsConstraintsPlan", "submitName"],
  methods: {
    addSlice() {
      this.grantsConstraintsPlan.grantsConstraintsPlansSlidesDetailsData.push(
        new GrantsConstraintsPlansSlidDetails()
      );
    },
    removeSlice(index) {
      this.grantsConstraintsPlan.grantsConstraintsPlansSlidesDetailsData.splice(
        index,
        1
      );
    },
    async getDialogOfGrantTypes() {
      this.isLoading = true;
      this.grantTypeTokenOptions = [];
      try {
        let itemsData =
          this.$store.getters.userData.constantLists.listGrantType;
        for (let item in itemsData) {
          this.grantTypeTokenOptions.push({
            value: itemsData[item]["itemToken"],
            text: setDataMultiLang(
              this.language,
              itemsData[item]["itemNameAr"],
              itemsData[item]["itemNameEn"]
            ),
          });
        }
      } catch (error) {
        this.grantTypeTokenOptions = [];
      }
      this.isLoading = false;
    },
    addOrUpdateGrantsConstraintsPlans() {
      this.$emit("addOrUpdateGrantsConstraintsPlans");
    },
  },
  watch: {},
  created() {
    this.getDialogOfGrantTypes();
  },
};
</script>
